/* eslint-disable react/prop-types */
import React from "react";

/**
 * Componente para renderizar conteúdos HTML crus.
 */
export default function HtmlBlock({ content, id }) {
	return (
		<div
			id={id}
			dangerouslySetInnerHTML={{
				__html: content,
			}}
		></div>
	);
}
