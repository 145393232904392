/* eslint-disable react/prop-types */
import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";

/**
 * Componente para renderizar conteúdos HTML crus.
 */
export default function SocialBar() {
	return (
		<MDBContainer fluid className="my-4 text-center">
			<MDBRow>
				<MDBCol>
					<h3>
						Conecte-se com a <span className="text-green">Feevale</span>
					</h3>
				</MDBCol>
			</MDBRow>
			<MDBRow className="d-inline-flex">
				{[
					["https://twitter.com/Feevale", "twitter"],
					["https://www.instagram.com/feevale/", "instagram"],
					["https://www.facebook.com/feevale", "facebook-f"],
					["https://br.linkedin.com/company/feevale", "linkedin"],
					["https://www.youtube.com/user/tvfeevale", "youtube"],
					["https://www.tiktok.com/@feevale", "tiktok"],
					[
						"https://open.spotify.com/user/8mdbgc7knnnwq7e7s56tid1pc?si=T2fGI3xTRBK5Y9OYE4mjXg",
						"spotify",
					],
				].map((i, index) => {
					return (
						<MDBCol
							key={"socialbar" + index}
							className="text-center py-3 px-0"
						>
							<a href={i[0]} target="_blank" rel="noreferrer" >
								<span className="fa-stack fa-2x text-green">
									<MDBIcon
										icon="circle fa-stack-2x"
										size="2x"
									/>
									<MDBIcon fab icon={`${i[1]} text-white fa-stack-1x`} />
								</span>
							</a>
						</MDBCol>
					);
				})}
			</MDBRow>
		</MDBContainer>
	);
}
