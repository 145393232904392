import React from "react";
import {
	MDBRow,
	MDBCol,
} from "mdbreact";

export default function ImageGalleryBlock({ blockName, img, link, altText }) {
	return (
		<MDBRow id={blockName}>
			<MDBCol className="text-center">
				<div className="px-1 my-2">
					{link ? (
						<a href={link} target="_blank">
							<img
								className="img-fluid"
								src={img}
								alt={altText}
							/>
						</a>
					) : (
						<img
							className="img-fluid"
							src={img}
							alt={altText}
						/>
					)}
				</div>
			</MDBCol>
		</MDBRow>
	);
}
