/* eslint-disable react/prop-types */
import React from "react";
import { MDBRow, MDBCol, MDBIcon, MDBBtn } from "mdbreact";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export default function ImageGalleryBlock({ items, id}) {
	// img: 
	// link: 
	// altText

	// Define os botões de avanço e retorno do carousel
	const ButtonGroup = ({ next, previous }) => {
		return (
			<>
				<MDBIcon
					className="text-black d-none d-md-block"
					style={{
						position: "absolute",
						zIndex: 1000,
						left: "-4%",
						top:"40%",
						transform: "translateY(50%)",
						backgroundColor: "transparent",
						cursor: "pointer",
					}}
					icon="chevron-left"
					size="3x"
					onClick={() => previous()}
				/>
				<MDBIcon
					className="text-black d-none d-md-block"
					style={{
						position: "absolute",
						zIndex: 1000,
						right: "-4%",
						top:"40%",
						transform: "translateY(50%)",
						backgroundColor: "transparent",
						cursor: "pointer",
					}}
					icon="chevron-right"
					size="3x"
					onClick={() => next()}
				/>

				<MDBRow
					className="d-flex d-md-none"
					style={{
						position: "absolute",
						top: "-3%",
						left: "50%",
						transform: "translateX(-42%)",
					}}
				>
					<MDBCol className="d-flex p-0 pr-4">
						<MDBBtn color="success" outline className="arrow-carrousel m-0" onClick={() => previous()}>
							<span className="d-flex">
								<MDBIcon icon="chevron-left text-green" className="p-2" />
							</span>
						</MDBBtn>
					</MDBCol>
					<MDBCol className="d-flex p-0 pl-4">
						<MDBBtn color="success" outline className="arrow-carrousel m-0" onClick={() => next()}>
							<span className="d-flex">
								<MDBIcon icon="chevron-right text-green" className="p-2" />
							</span>
						</MDBBtn>
					</MDBCol>
				</MDBRow>
			</>
		);
	};

	const responsive = {
		desktop: {
			breakpoint: { max: 3000, min: 1025 },
			items: 1,
			slidesToSlide: 1, // optional, default to 1.
		},
		tablet: {
			breakpoint: { max: 1024, min: 465 },
			items: 1,
			slidesToSlide: 1, // optional, default to 1.
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
			slidesToSlide: 1, // optional, default to 1.
		},
	};

	return (
		<MDBRow id={id}>
			{items !== null && items.length > 0 && (
				<MDBCol className={items.length > 1 ? "pt-5 mt-4 pt-md-3" : "pt-2"} >
					<Carousel
						swipeable={true}
						draggable={false}
						showDots={false}
						responsive={responsive}
						ssr={true} // means to render carousel on server-side.
						infinite={items.length > 1 ? true : false}
						autoPlay={items.length > 1 ? true : false}
						autoPlaySpeed={3000}
						arrows={false}
						itemClass="px-1 my-2 rounded"
						renderButtonGroupOutside={items.length > 1 ? true : false}
						customButtonGroup={items.length > 1 && <ButtonGroup />}
						containerClass="carousel-container py-1 text-center"
					>
						{items?.map((item, index) => (
							<>
								{item.link? 
									<a key={index} href={item.link} target="_blank">
										<img  style={{ width: "100%", height: "100%" }} src={item.img} alt={item.altText}/>
									</a>
								:
									<img key={index} style={{ width: "100%", height: "100%" }} src={item.img} alt={item.altText}/>
								}
							</>
						))}
					</Carousel>
				</MDBCol>
			)}
		</MDBRow>
	);
}