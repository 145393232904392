/* eslint-disable react/prop-types */
import * as React from "react";
import Layout from "../layouts/MasterLayout/MasterLayout";
import HtmlBlock from "../components/Blocks/HtmlBlock/HtmlBlock";
import DocumentBlock from "../components/Blocks/DocumentBlock/DocumentBlock";
import MarkdownBlock from "../components/Blocks/MarkdownBlock/MarkdownBlock";
import ImageGalleryBlock from "../components/Blocks/ImageGalleryBlock/ImageGalleryBlock";
import ImageBlock from "../components/Blocks/ImageBlock/ImageBlock";
import SEO from "../components/SEO/SEO";
import { FormatDate } from "../helpers/formatters";

import { Link } from "gatsby";

import {
	MDBCol,
	MDBContainer,
	MDBRow,
	MDBBtn,
	MDBIcon,
	MDBBadge,
} from "mdbreact";

export default function AvisoPage({ pageContext: { aviso } }) {
	let blocos = [];

	// Trata os blocos de conteúdo
	if (aviso?.Blocks?.length > 0) {
		aviso.Blocks.forEach((block) => {
			switch (block.Type) {
				case "Piranha.Extend.Blocks.HtmlBlock":
					blocos.push(<HtmlBlock id={block.Type} content={block.Body.Value} />);
					break;
				case "FeevaleData.Models.Blocks.RawHtmlBlock":
					blocos.push(<HtmlBlock id={block.Type} content={block.Body.Value} />);
					break;
				case "Piranha.Extend.Blocks.SeparatorBlock":
					blocos.push(<hr id={block.Type} />);
					break;
				case "FeevaleData.Models.Blocks.DocumentGroupBlock":
					let documents = [];

					block?.Items?.forEach((doc) => {
						if (doc?.Body?.Media !== null) {
							documents.push({
								Title: doc.Body.Media.Title,
								ContentType: doc.Body.Media.ContentType,
								PublicUrl: doc.Body.Media.PublicUrl,
							});
						}
					});

					blocos.push(<DocumentBlock id={block.Type} items={documents} />);
					break;
				case "FeevaleData.Models.Blocks.DocumentBlock":
					blocos.push(
						<DocumentBlock
							id={block.Type}
							items={[
								{
									Title: block.Body.Media.Title,
									ContentType: block.Body.Media.ContentType,
									PublicUrl: block.Body.Media.PublicUrl,
								},
							]}
						/>
					);
					break;
				case "Piranha.Extend.Blocks.MarkdownBlock":
					blocos.push(
						<MarkdownBlock
							id={block.Type}
							content={block.Body.Value}
						/>
					);
					break;
				case "Piranha.Extend.Blocks.ImageGalleryBlock":
					let images = [];

					block?.Items?.forEach((img) => {
						if (img?.Body?.Media !== null) {
							images.push({
								img: img.Body.Media.PublicUrl,
								link: img.Body.Media.Description,
								altText: img.Body.Media.AltText
							});
						}
					});
					blocos.push(<ImageGalleryBlock id={block.Type} items={images} />);
					break;
				case "Piranha.Extend.Blocks.ImageBlock":
					if (block?.Body?.HasValue) {
						blocos.push(
							<ImageBlock
								blockName={block.Type}
								img={block.Body.Media.PublicUrl}
								link={block.Body.Media.Description}
								altText={block.Body.Media.AltText}
							/>
						);
					}
					break;
			}
		});
	}

	return (
		<Layout>
			<SEO title={aviso?.Titulo} />
			<MDBContainer>
				<MDBRow>
					<MDBCol className="my-5">
						{aviso?.Tipo?.map((tipo) => (
							<MDBBadge pill color="2EC034" className="mr-1">
								{tipo}
							</MDBBadge>
						))}
						<h1 style={{ color: "#2ec034" }}>{aviso?.Titulo}</h1>
						<p className="text-muted small font-weight-light">
							Última modificação: {FormatDate(aviso?.UltimaModificacao)}
						</p>
						<div>
							{blocos?.map((bloco) => {
								return bloco;
							})}
						</div>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol className="d-flex justify-content-md-end justify-content-center">
						<Link to="/">
							<MDBBtn color="success" outline className="px-5">
								<MDBIcon icon="chevron-circle-left" aria-hidden="true" /> Ver
								todos os avisos
							</MDBBtn>
						</Link>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		</Layout>
	);
}
