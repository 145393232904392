/* eslint-disable no-undef */
import React, { useEffect } from "react";
import PropTypes from "prop-types";

// Imports Material Design
import "bootstrap-css-only/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "../../assets/mdbreact/scss/mdb-free.scss";

import { MDBContainer, MDBRow, MDBCol } from "mdbreact";

import MainNavbar from "../../components/MainNavbar/MainNavbar";
import MainFooter from "../../components/MainFooter/MainFooter";
import SEO from "../../components/SEO/SEO";
import SocialBar from "../../components/SocialBar/SocialBar";

export default function Layout({ children }) {
	Layout.propTypes = {
		children: PropTypes.object.isRequired,
	};

	useEffect(() => {
		if (
			process.env.NODE_ENV === "production" &&
			typeof window !== "undefined"
		) {
			// HandTalk
			let script = document.createElement("script");
			script.src = "//plugin.handtalk.me/web/latest/handtalk.min.js";
			script.async = true;
			script.onload = () => {
				new window.HT({
					token: "67d8ad24f158ff8b093ac95c14fb6b67",
					maxTextSize: 1000,
					pageSpeech: true,
				});
			};
			document.body.appendChild(script);

			// RD Station
			script = document.createElement("script");
			script.src =
				"https://d335luupugsy2.cloudfront.net/js/loader-scripts/168bf134-2e44-4e66-ab9a-cdb7f59837c2-loader.js";
			script.async = true;
			document.body.appendChild(script);

			(function(c,l,a,r,i,t,y){
				c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
				t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
				y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
			})(window, document, "clarity", "script", "a36lm1cyfb");		
		}
	});

	return (
		<>
			<SEO />
			<MainNavbar />
			<MDBContainer fluid>
				<MDBRow>
					<MDBCol style={{ paddingLeft: 0, paddingRight: 0 }}>
						{children}
					</MDBCol>
				</MDBRow>
			</MDBContainer>
			<SocialBar />
			<MainFooter />
		</>
	);
}
