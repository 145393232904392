/* eslint-disable react/prop-types */
import React from "react";
import { MDBRow, MDBCol, MDBIcon, MDBCard } from "mdbreact";

export default function DocumentBlock({ items, id}) {

	function renderIcon(type){
		switch(type) {
			case 'application/pdf': // PDF
				return <MDBIcon far icon="file-pdf" size="3x" />
			case 'text/csv': // CSV
			case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': // Excel
				return <MDBIcon far icon="file-excel" size="3x" />
			case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': // Word
				return <MDBIcon far icon="file-word" size="3x" />
			case 'image/png':
				return <MDBIcon far icon="file-image" size="3x" />
			default:
				return <MDBIcon far icon="file-alt" size="3x" />
		}
	}

	return (
		<MDBRow  id={id} >
			{items.map((i) => {
				return (
					<MDBCol key={i.Title} col="12" md="4" className="pt-2">
						<a
							href={i.PublicUrl}
							style={{ color: "#3c3" }}
							color="green"
							target="_blank"
							rel="noreferrer"
							className="text-decoration-none"
						>
							<MDBCard className="h-100">
								<MDBRow className="my-4 mx-2">
									<MDBCol className="d-flex">
									{renderIcon(i.ContentType)}
									<div className="pl-4 my-auto">
										<h6 className="m-0" style={{color: "#6d6e72"}}>{i.Title}</h6>
										<span className="font-small"><MDBIcon icon="download" /> Visualizar</span>
									</div>
									</MDBCol>
								</MDBRow>
							</MDBCard>
						</a>
					</MDBCol>
				);
			})}
		</MDBRow>
	);
}
